<template>
  <menu-principal>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <titulo-pagina>Alterar Senha</titulo-pagina>
        </v-col>

        <v-col cols="12" v-if="!usuarioEncontrado">
          <bloco-erro>Usuário não Encontrado</bloco-erro>
        </v-col>

        <v-col cols="12" v-if="usuarioEncontrado">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="12" :md="!pedirSenhaAtual ? 6: 4" v-if="pedirSenhaAtual">
                  <v-text-field
                      v-model.trim="formulario.senhaAtual"
                      label="Senha Atual"
                      :error-messages="erros.senhaAtual"
                      background-color="white"
                      dense
                      prepend-inner-icon="mdi-lock"
                      outlined
                      type="password"
                      :append-icon="(!!erros.senhaAtual && iconeErro) || ''"
                  />
                </v-col>

                <v-col cols="12" :md="!pedirSenhaAtual ? 6: 4">
                  <v-text-field
                      v-model.trim="formulario.novaSenha"
                      label="Nova Senha"
                      :error-messages="erros.novaSenha"
                      background-color="white"
                      dense
                      prepend-inner-icon="mdi-lock"
                      outlined
                      counter
                      maxlength="15"
                      type="password"
                      :append-icon="(!!erros.novaSenha && iconeErro) || ''"
                  />
                </v-col>

                <v-col cols="12" :md="!pedirSenhaAtual ? 6: 4">
                  <v-text-field
                      v-model.trim="formulario.confirmacaoNovaSenha"
                      label="Confirmar Nova Atual"
                      :error-messages="erros.confirmacaoNovaSenha"
                      background-color="white"
                      dense
                      prepend-inner-icon="mdi-lock"
                      outlined
                      counter
                      maxlength="15"
                      type="password"
                      :append-icon="(!!erros.confirmacaoNovaSenha && iconeErro) || ''"
                  />
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col cols="12" align="center">
                  <span class="font-size-14 font-weight-medium deep-orange--text text--accent-3">
                    * A senha deve conter entre 6 e 15 caracteres, sendo pelo menos um caractere maiúsculo, um
                    minúsculo, um especial e um numérico.
                  </span>
                </v-col>
              </v-row>
              <v-row>
                <v-spacer></v-spacer>
                  <v-btn
                      :block="blockBtns"
                      class="white--text"
                      :class="{'mr-4': !blockBtns,'mb-4': blockBtns}"
                      depressed
                      color="orange"
                      @click="confirmarSaida()"
                  >
                    Ir para Listagem
                    <v-icon right>
                      mdi-table-account
                    </v-icon>
                  </v-btn>

                  <v-btn
                      :block="blockBtns"
                      dark
                      depressed
                      @click="submit()"
                      color="var(--cor-primaria-100)"
                  >
                    Confirmar
                    <v-icon right>
                      mdi-send-check
                    </v-icon>
                  </v-btn>
              </v-row>
            </v-card-text>
          </v-card>


        </v-col>
      </v-row>
    </v-container>
  </menu-principal>
</template>

<script>
import MenuPrincipal from "../../components/MenuPrincipal";
import TituloPagina from "../../components/TituloPagina";
import BlocoErro from "../../components/BlocoErro";
import mixinGeral from "../../mixin/geral";
import usuariosServices from "../../services/usuarios";
import helpers from "@/assets/js/helpers";

export default {
  name: "AlterarSenha",
  mixins: [mixinGeral],
  components: {
    MenuPrincipal,
    TituloPagina,
    BlocoErro,
  },
  data() {
    return {
      usuarioEncontrado: true,
      idUsuario: null,
      formulario: {
        senhaAtual: "",
        novaSenha: "",
        confirmacaoNovaSenha: "",
      },
      erros: {
        senhaAtual: null,
        novaSenha: null,
        confirmacaoNovaSenha: null,
      },
    };
  },
  computed: {
    pedirSenhaAtual() {
      return !this.permissaoMaster && !this.permissaoAdmin;
    },
    iconeErro() {
      return "mdi-alert-octagram";
    },
    habilitacaoSubmit() {
      return !this.loading && !this.notificacao.status;
    },
    blockBtns() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    buscarUsuario() {
      const id = this.$route.params.id;

      if (Number(this.usuarioLogado.id) === Number(id)) {
        return this.usuarioLogado;
      }

      return this.usuarios.find((u) => Number(u.id) === Number(id));
    },
    confirmarSaida() {
      this.$confirm("Deseja ser direcionado para lista de Usuarios?").then((res) => {
        if (res) {
          this.navegar("usuariosIndex");
        }
      })
    },
    async submit() {
      this.erros = {};
      this.ativarLoading();
      try {
        await usuariosServices.alterarSenha(this.idUsuario, this.formulario);
        this.notificacaoSucesso("Senha Alterada");
        this.navegar("usuariosIndex");
      } catch (e) {
        this.interpretarErro(e);
        if (this.obterCodigoRequisicao(e) === 422) {
          this.apresentarErros(e.response.data.erros);
        }
      } finally {
        this.desativarLoading();
      }
    },
    apresentarErros(erros) {
      if (!helpers.empty(erros.senha_atual)) this.erros.senhaAtual = erros.senha_atual[0];
      if (!helpers.empty(erros.nova_senha)) this.erros.novaSenha = erros.nova_senha[0];
      if (!helpers.empty(erros.confirmacao_nova_senha))
        this.erros.confirmacaoNovaSenha = erros.confirmacao_nova_senha[0];
    },
  },
  watch: {},
  created() {
    const usuario = this.buscarUsuario();
    if (!usuario) {
      this.usuarioEncontrado = false;
    } else {
      this.idUsuario = usuario.id;
    }
  },
};
</script>

<style scoped>
.sombra {
  -webkit-box-shadow: rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset !important;
  box-shadow: rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset !important;
}
</style>